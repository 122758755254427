import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LoggerService } from '@progbonus/logger/logger.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class RolesGuard implements CanActivate {
    constructor(
        private readonly _auth: AuthService,
        private readonly router: Router,
        private readonly _logger: LoggerService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const roles = (route.data as any).expectedRoles;
        if (!this._auth.userHasRoles(roles)) {
            this._logger.info(`User is not in required roles`, roles);
            this._auth.logout();
            return false;
        }
        return true;
    }
}
