import { AuthService } from '@progbonus/auth/auth.service';
import { CurrentMarketService } from './market/current-market.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { NavHelper, buildNavs } from 'app/navigation/navigation';
import { replaceAll } from '@progbonus/helpers/string.helpers';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "./market/current-market.service";
import * as i3 from "../../@fuse/components/navigation/navigation.service";
import * as i4 from "@angular/router";
import * as i5 from "../logger/logger.service";
export class MarketReportLinks {
    constructor(_base) {
        this._base = _base;
    }
    get self() {
        return this._base + '/reports';
    }
    get customers() {
        return `${this.self}/customers`;
    }
    get instagram() {
        return `${this.self}/instagram`;
    }
    get main() {
        return `${this.self}/main`;
    }
    get birthday() {
        return `${this.self}/birthday`;
    }
    get registration() {
        return `${this.self}/registration`;
    }
    get bonus() {
        return `${this.self}/bonus`;
    }
    get purchases() {
        return `${this.self}/purchases`;
    }
    get sms() {
        return `${this.self}/sms`;
    }
}
export class AppNavService {
    constructor(_auth, _currentCompany, _fuseNavigationService, _router, _logger) {
        this._auth = _auth;
        this._currentCompany = _currentCompany;
        this._fuseNavigationService = _fuseNavigationService;
        this._router = _router;
        this._logger = _logger;
        this.emptyKey = 'empty';
        // Get default navigation
        this._navigation = [];
        // Register the navigation to the service
        this._fuseNavigationService.register(this.emptyKey, this._navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation(this.emptyKey);
        this._init();
    }
    get currentMarketKey() {
        return this._currentMarketKey;
    }
    start() {
        // dumpy
    }
    _userWithoutMarket(user) {
        if (!user) {
            this._logger.info(`navigation for null user can't be update`);
            return;
        }
        const navKey = this._navKeyForRole ||
            NavHelper.getNavKeyForRoles(this._auth.roles);
        const navs = buildNavs(this._auth.roles);
        if (!this._navKeyForRole) {
            // Register the new navigation
            this._fuseNavigationService.register(navKey, navs);
        }
        // Set the current navigation
        this._fuseNavigationService.setCurrentNavigation(navKey);
        this._navKeyForRole = navKey;
        this._logger.info(`App Navs has been initialized as ${navKey} for`, this._auth.roles);
    }
    _init() {
        this._cached = {};
        this._navUpdateSub = combineLatest([
            this._auth.currentUser$,
            this._currentCompany.currentMarket$
        ]).subscribe(arr => {
            const user = arr[0];
            const market = arr[1];
            if (!user) {
                // Set the current navigation
                this._fuseNavigationService.setCurrentNavigation(this.emptyKey);
                return;
            }
            if (!market) {
                this._userWithoutMarket(this._auth.currentUserValue);
                return;
            }
            const roles = [...this._auth.roles];
            if (this._auth.isArchitector ||
                this._auth.isHero ||
                this._auth.isFranchisee) {
                roles.push('owner');
            }
            console.log(`MARKET FOR NAVS`, market);
            const navs = buildNavs(roles, market);
            const key = `market-${market.companyCode}`;
            this._currentMarketKey = key;
            if (!this._fuseNavigationService.getNavigation(key)) {
                this._fuseNavigationService.register(key, navs);
            }
            this._fuseNavigationService.setCurrentNavigation(key);
            this._logger.info(`App Navs has been initialized as ${key} with market for`, this._auth.roles);
        });
    }
    clearMarket() {
        if (!this._auth.isOwnerOrSeller) {
            this._userWithoutMarket(this._auth.currentUserValue);
            this._cached = {};
        }
    }
    composeMarketNavForUser(marketId = null, args = []) {
        let url = this._urlOfMarket(marketId);
        if (!args.length) {
            return url;
        }
        for (const a of args) {
            url += `/${replaceAll(a, '/', '')}`;
        }
        return url;
    }
    _urlOfMarket(marketId = null) {
        return this._auth.isOwnerOrSeller ? '/market' : `/markets/${marketId}`;
    }
    market(marketId) {
        if (this._cached[marketId]) {
            return this._cached[marketId];
        }
        const url = this._urlOfMarket(marketId);
        const urls = {
            reports: new MarketReportLinks(url)
        };
        this._cached[marketId] = urls;
        return this._cached[marketId];
    }
    navigateToMarket(marketId, params, extras = null) {
        const url = this.composeMarketNavForUser(marketId, params);
        // this._logger.info(`>>> url has been built: ${url}`);
        this._router.navigate([url, extras || {}]);
    }
    navigate(url, extras = null) {
        // const navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         firstname: 'Nic',
        //         lastname: 'Raboy'
        //     }
        // };
        this._router.navigate([url]);
    }
}
AppNavService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppNavService_Factory() { return new AppNavService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.CurrentMarketService), i0.ɵɵinject(i3.FuseNavigationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LoggerService)); }, token: AppNavService, providedIn: "root" });
