import { AuthGuard } from '@progbonus/auth/auth.guard';
import { RolesGuard } from '@progbonus/auth/role.guard';
import { LogoutComponent } from './main/logout/logout.component';
const ɵ0 = () => import("./main/login/login.module.ngfactory").then(x => x.LoginModuleNgFactory), ɵ1 = () => import("./main/lock/lock.module.ngfactory").then(x => x.LockModuleNgFactory), ɵ2 = () => import("./main/profile/profile.module.ngfactory").then(x => x.ProfileModuleNgFactory), ɵ3 = () => import("./main/market/market.module.ngfactory").then(x => x.MarketModuleNgFactory), ɵ4 = () => import("./main/market/market.module.ngfactory").then(x => x.MarketModuleNgFactory), ɵ5 = () => import("./main/hero/hero.module.ngfactory").then(x => x.HeroModuleNgFactory), ɵ6 = { expectedRoles: ['hero', 'manager', 'admin'] }, ɵ7 = () => import("./main/architect/architect.module.ngfactory").then(x => x.ArchitectModuleNgFactory), ɵ8 = { expectedRoles: ['architect'] }, ɵ9 = () => import("./main/franchisee/franchisee.module.ngfactory").then(x => x.FranchiseeModuleNgFactory), ɵ10 = { expectedRoles: ['franchisee'] };
export const appRoutes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'callback'
    },
    {
        path: 'login',
        loadChildren: ɵ0
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'callback',
        loadChildren: ɵ1
    },
    {
        path: 'user/profile',
        canActivate: [AuthGuard],
        loadChildren: ɵ2
    },
    {
        path: 'market',
        canActivate: [AuthGuard],
        loadChildren: ɵ3
    },
    {
        path: 'markets/:companyId',
        canActivate: [AuthGuard],
        loadChildren: ɵ4
    },
    {
        path: 'hero',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: ɵ5,
        data: ɵ6
    },
    {
        path: 'architect',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: ɵ7,
        data: ɵ8
    },
    {
        path: 'franchisee',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: ɵ9,
        data: ɵ10
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
