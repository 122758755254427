import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    HostListener
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { takeUntil, switchMap, catchError, filter, map } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationRussian } from 'app/navigation/i18n/ru';
import { AuthService } from '@progbonus/auth/auth.service';
import { User } from '@progbonus/models/user.model';
import { LoggerService } from '@progbonus/logger/logger.service';
import { Router, NavigationError } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CurrentMarketService } from '@progbonus/services/market/current-market.service';
import { AppNavService } from '@progbonus/services/app-nav.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        public readonly auth: AuthService,
        private readonly _currentCompany: CurrentMarketService,
        private readonly _router: Router,
        private readonly _appNav: AppNavService,
        private readonly snackBar: MatSnackBar,
        private readonly cookieService: CookieService,
        private readonly _logger: LoggerService
    ) {
        this.auth.handleAuthentication();
        this.auth.scheduleRenewal();

        this._appNav.start();

        // Add languages
        this._translateService.addLangs(['en', 'ru']);

        const cachedLang = localStorage.getItem('currentLanguage') || 'ru';

        // Set the default language
        this._translateService.setDefaultLang(cachedLang);

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationRussian
        );

        // Use a language
        this._translateService.use(cachedLang);

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.auth.isAuthenticated) {
            this.auth.renewTokens(true);
        }

        this.auth.authData$
            .pipe(
                filter(x => x && x.user),
                map(x => x.user),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(
                (user: User) => {
                    if (user) {
                        this._logger.info('Successfully get user data.');

                        const navigateTo = this.auth.isArchitector
                            ? '/hero/companies'
                            : this.auth.isHero
                            ? '/hero/companies'
                            : this.auth.isFranchisee
                            ? '/franchisee/companies'
                            : this.auth.isOwner
                            ? '/market/customers'
                            : this.auth.isSeller
                            ? '/market/customers'
                            : null;

                        this._logger.info(`Initial page is ${navigateTo}`);

                        // setTimeout(() => {
                        this._router.navigate([navigateTo]);
                        // }, 100);
                    } else {
                        this._logger.info(`Could not get user data!`);
                        // this.auth.logout();
                    }
                },
                (err: any) => {
                    this._logger.info(`Could not get user data!`, err);
                    this.auth.logout();
                }
            );

        this._router.events.subscribe(event => {
            if (event instanceof NavigationError) {
                this.snackBar.open('Нельзя перейти на другую страницу', '', {
                    duration: 3000
                });
            }
        });

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    @HostListener('window:beforeunload', ['$event'])
    clearCookie($event: any): any {
        // this.cookieService.deleteAll('/');
        // window.localStorage['myUnloadEventFlag'] = new Date().getTime();
        // const cookies = document.cookie.split(';');
        // for (const cookie of cookies) {
        //     const eqPos = cookie.indexOf('=');
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     window.localStorage[name] = cookie;
        //     // document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        // }
        // const allCookies: {} = this.cookieService.getAll();
        // window.localStorage['allcookies'] = JSON.stringify(allCookies);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
