const ɵ0 = {
    NAV: {
        APPLICATIONS: 'Applications',
        SAMPLE: {
            TITLE: 'Sample',
            BADGE: '25'
        },
        ARCHITECT: {
            TITLE: 'Architect',
            COUNTRIES: 'Countries',
            INSTAGRAM: 'Instagram'
        },
        HERO: {
            DASHBOARD: 'Dashboard',
            COMPANIES: 'Companies',
            FRANCHISEES: 'Franchisees',
            CITIES: 'Cities',
            reports: 'Reports'
        },
        FRANCHISEE: {
            title: 'Franchisee',
            DASHBOARD: 'Dashboard',
            COMPANIES: 'Companies'
        },
        MARKET: {
            DASHBOARD: 'Dashboard',
            STORES: 'Stores',
            SELLERS: 'Sellers',
            CUSTOMERS: 'Customers',
            PURCHASES: 'Purchases',
            COUPONS: 'Coupons',
            PROMOTIONS: 'Promotions',
            SMS: 'SMS',
            EVENTS: 'Events',
            BONUSESWRITEOFFS: 'Bonuses & Write-offs',
            INSTAGRAM: 'Instagram',
            REPORTS: 'Reports',
            SETTINGS: 'Settings',
            BONUSES: 'Bonuses'
        }
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
