import { Component, OnInit } from '@angular/core';
import { AuthService } from '@progbonus/auth/auth.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    constructor(private readonly _auth: AuthService) {}

    ngOnInit(): void {
        this._auth.logout();
    }
}
