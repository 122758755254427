import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

export abstract class BaseApiService {
    get baseUrl(): string {
        return environment.progbonusApi;
    }

    constructor(protected readonly http: HttpClient) {}
}
