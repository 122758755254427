import { AuthService } from '@progbonus/auth/auth.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "../logger/logger.service";
export class DisplayService {
    constructor(_auth, _logger) {
        this._auth = _auth;
        this._logger = _logger;
        this._auth.currentUser$
            .pipe(tap(u => this._logger.info(`Display for user:`, u)))
            .subscribe(u => {
            this.avatar = 'assets/images/avatars/profile.jpg';
            if (!u) {
                this.username = null;
                this.email = null;
                return;
            }
            const username = u.username;
            const name = `${u.firstName || ''} ${u.lastName || ''}`.trim();
            const role = u.roles.some(x => x === 'seller' || x === 'cashier')
                ? 'Продавец'
                : u.roles.some(x => x === 'owner')
                    ? 'Владелец'
                    : null;
            this.username = name
                ? name
                : username
                    ? username
                    : role
                        ? role
                        : null;
            this.email = u.email;
        });
    }
}
DisplayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisplayService_Factory() { return new DisplayService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LoggerService)); }, token: DisplayService, providedIn: "root" });
