import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@progbonus/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _auth: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                console.log(`Http Response Error`, err);

                switch (err.status) {
                    case 0:
                        console.log(`Server has not answered!`);
                        break;
                    case 404:
                        console.log(`User mistake!`);
                        break;
                    case 401:
                        // auto logout if 401 response returned from api
                        this._auth.logout();
                        location.reload(true);
                        break;
                    case 404:
                        console.log(`Not found!`);
                        break;
                    default:
                        console.log(`Error code: ${err.status}`);
                        break;
                }

                const error =
                    err.error ? err.error.message : /** App Model Error */
                        err.message ||
                        err.statusText;

                const errModel = {
                    message: error,
                    data: err.error || null
                };

                return throwError(error);
            })
        );
    }
}
