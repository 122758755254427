import { BaseApiService } from './base-api.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../logger/logger.service";
export class IAmService extends BaseApiService {
    constructor(http, _logger) {
        super(http);
        this.http = http;
        this._logger = _logger;
        this._myUrl = `${this.baseUrl}api/users/iam`;
    }
    getInfo() {
        this._logger.info(`Try get me...`);
        return this.http.get(this._myUrl).pipe(tap(x => this._logger.info(`I am`, x)), catchError(error => {
            this._logger.info(`I am with error`, error);
            return of(null);
        }));
    }
}
IAmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IAmService_Factory() { return new IAmService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoggerService)); }, token: IAmService, providedIn: "root" });
