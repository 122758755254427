import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@progbonus/auth/auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _auth: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.url.startsWith(environment.progbonusApi) ||
            request.url.startsWith(environment.instagramApi) ||
            request.url.startsWith(environment.reportsApi)
        ) {
            // add authorization header with jwt token if available
            const token = this._auth.accessToken;
            // if (currentUser && currentUser.token) {
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        }

        return next.handle(request);
    }
}
