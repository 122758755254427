import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Market } from '@progbonus/models/market.model';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { StorePoint } from '@progbonus/models';
import { LoggerService } from '@progbonus/logger/logger.service';
import { FirestoreService } from '../firestore.service';

@Injectable({
    providedIn: 'root'
})
export class CurrentMarketService {
    private get _currentMarketKey(): string {
        return 'currentMarket';
    }

    private get _currentStoreKey(): string {
        return 'currentStoreId';
    }

    private _storeId?: number;

    onSellerStoreChanged: Subject<any> = new Subject();

    get id(): string {
        return this.currentMarketValue
            ? this.currentMarketValue.companyId
            : null;
    }

    get code(): string {
        return this.currentMarketValue
            ? this.currentMarketValue.companyCode
            : null;
    }

    get tz(): number {
        return this.currentMarketValue ? this.currentMarketValue.cityTz : null;
    }

    get now(): Date {
        return this.currentMarketValue ? new Date() : null;
    }

    get today(): Date {
        return this.now ? new Date(this.now.toDateString()) : null;
    }

    get phoneCode(): number {
        return this.currentMarketValue
            ? this.currentMarketValue.phoneCode
            : null;
    }

    get hasUtmSources(): boolean {
        return false;
    }

    private _currentMarketSubject: BehaviorSubject<Market>;
    public currentMarket$: Observable<Market>;

    public get currentMarketValue(): Market {
        return this._currentMarketSubject.value;
    }

    private _firestoreCompany$: Subscription;

    constructor(
        private readonly _http: HttpClient,
        private readonly _afs: FirestoreService,
        private readonly _logger: LoggerService
    ) {
        this._currentMarketSubject = new BehaviorSubject<Market>(
            JSON.parse(localStorage.getItem(this._currentMarketKey))
        );
        this.currentMarket$ = this._currentMarketSubject.asObservable();

        this._firestoreCompany$ = this.currentMarket$.subscribe(x =>
            x
                ? this._afs
                    .doc$(`companies/${x.companyCode}`)
                    .subscribe(company => {
                        this._logger.info(
                            `Company from firestore is`,
                            company
                        );
                    })
                : null
        );
    }

    load(market: Market): void {
        if (this.id === market.companyId) {
            return;
        }

        this.clear();
        localStorage.setItem(this._currentMarketKey, JSON.stringify(market));
        this._currentMarketSubject.next(market);
    }

    get storeId(): number {
        if (!this._storeId) {
            if (+localStorage[this._currentStoreKey]) {
                this.setStoreId(+localStorage[this._currentStoreKey]);
            }
        }
        return this._storeId;
    }

    setStoreId(storeId: number | null): void {
        localStorage[this._currentStoreKey] = storeId;
        this._storeId = storeId;
        this.onSellerStoreChanged.next(this.storeId);
    }

    setStore(store: StorePoint): void {
        this.setStoreId(store ? store.storeId : null);
    }

    clear(): void {
        localStorage.removeItem(this._currentMarketKey);
        localStorage.removeItem(this._currentStoreKey);
        this._currentMarketSubject.next(null);
    }
}
