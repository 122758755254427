import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    mergeMap,
    take,
    switchMap,
    tap,
    finalize,
    filter,
    map
} from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import {
    BehaviorSubject,
    of,
    timer,
    Observable,
    combineLatest,
    Subscription
} from 'rxjs';
import { User } from '@progbonus/models/user.model';
import { IAmService } from '@progbonus/services/iam.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';

(window as any).global = window;

export interface AuthData {
    user?: any;
    error?: any;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _idToken: string;
    private _accessToken: string;
    private _refreshToken: string;

    // private _isDebug: boolean = !environment.production;

    // Auth0 profile
    userProfile: any;

    readonly loginPage = '/login';
    readonly loginUniversal = '/login/universal';
    readonly profilePage = '/user/profile';
    readonly local_storage_key = {
        current_user: 'current_user',
        id_token: 'id_token',
        access_token: 'access_token',
        auth_result: 'auth_result',
        refresh_token: 'refresh_token'
    }

    // get isLoggedIn(): boolean {
    //     return this._expiresAt > 0;
    // }

    refreshSub: any;

    authData$: BehaviorSubject<AuthData>;

    // private _isAuthenticated = new BehaviorSubject(false);
    // isAuthenticated$: Observable<boolean>;

    public get isAuthenticated(): boolean {
        return !this._jwtHelper.isTokenExpired(this.accessToken);
    }

    // private _lastError = new BehaviorSubject(null);
    // lastError$: Observable<any>;

    isBusyToFetchUserData: boolean;

    // private currentUserSubject: BehaviorSubject<User>;
    public currentUser$: Observable<User>;

    private _currUser: User;
    public get currentUserValue(): any {
        return this._currUser;
    }

    public get userId(): string {
        return this.currentUserValue ? this.currentUserValue.id : null;
    }

    public get avatar(): string {
        return 'assets/images/avatars/profile.jpg';
    }

    public get email(): string {
        return this.currentUserValue ? this.currentUserValue.email : '';
    }

    public get displayName(): string {
        const name = this.currentUserValue
            ? `${this.currentUserValue.firstName || ''} ${
                  this.currentUserValue.lastName || ''
              }`.trim()
            : '';

        const role = this.currentUserValue
            ? this.currentUserValue.roles.some(
                  (x) => x === 'cashier' || x === 'seller'
              )
                ? 'Продавец'
                : null
            : null;

        return name || role;
    }

    public get scopes(): string[] {
        return this.currentUserValue ? this.currentUserValue.scope : [];
    }

    public get roles(): string[] {
        return this.currentUserValue && this.currentUserValue.roles
            ? this.currentUserValue.roles
            : [];
    }

    get expiresAt(): Date {
        return this._jwtHelper.getTokenExpirationDate(this.accessToken);
    }

    get isArchitector(): boolean {
        return this.hasRole('architect');
    }

    get isHero(): boolean {
        return this.hasRole('hero') || this.hasRole('manager');
    }

    get isSeller(): boolean {
        return this.hasRole('cashier') || this.hasRole('seller');
    }

    get isOwner(): boolean {
        return this.hasRole('owner');
    }

    get isOwnerOrSeller(): boolean {
        return this.isOwner || this.isSeller;
    }

    get isFranchisee(): boolean {
        return this.hasRole('franchisee');
    }

    private get _localUser(): User {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    private _getUserDataRequest$ = new BehaviorSubject<boolean>(false);
    private _getUserData$: Subscription;

    loggedInFirebase: boolean;
    firebaseSub: any;
    refreshFirebaseSub: any;

    constructor(
        private readonly _router: Router,
        private readonly _http: HttpClient,
        private readonly _fireAuth: AngularFireAuth,
        private readonly _iam: IAmService,
        private readonly _jwtHelper: JwtHelperService,
        private readonly _logger: LoggerService
    ) {
        this._idToken = localStorage.getItem('id_token');
        this._accessToken = tokenGetter();
        this._refreshToken = localStorage.getItem(
            this.local_storage_key.refresh_token
        );

        this.authData$ = new BehaviorSubject<AuthData>(null);

        this._getUserData$ = this._getUserDataRequest$
            .pipe(
                filter((x) => x),
                switchMap((x) => this._getInfo())
            )
            .subscribe((x) => {
                this._logger.info(`subscribed on user`);
                this.authData$.next({ user: x });
            });

        this.currentUser$ = this.authData$.pipe(
            tap((x) => (this._currUser = x ? x.user : null)),
            map((x) => (x ? x.user : null))
        );
    }

    get accessToken(): string {
        return this._accessToken;
    }

    get idToken(): string {
        return this._idToken;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    public authorize(): void {
       
    }

    public getProfile(cb: any): void {
        if (!this._accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }
    }

    public handleAuthentication(): void {
        this._logger.info(`handleAuthentication...`);
        let isLoggedIn = this.isLoggedIn();
        if (!isLoggedIn) {
            this.authData$.next({ error: 'error' });
            const url =
                location.href.indexOf('/login/universal') > -1
                    ? this.loginUniversal
                    : this.loginPage;
            this._router.navigate([url]);
        }
    }

    private _localLogin(authResult): void {
        // Set the time that the access token will expire at
        const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this._accessToken = authResult.accessToken;
        this._idToken = authResult.idToken;
        // localStorage.setItem('expires_at', expiresAt.toString());

        // if (this._isDebug) {
        localStorage.setItem('access_token', this.accessToken);
        localStorage.setItem('id_token', this.idToken);
        // }
    }

    public async renewTokens(isUpdateUser = false): Promise<void> {
        const body = new URLSearchParams({
            client_id: environment.identity.clientId,
            grant_type: 'refresh_token',
            refresh_token: this._refreshToken,
            scope: 'market.api.debug market.api openid profile offline_access'
        });
        await this.getAccessTokenFor(body, isUpdateUser);

    }

    scheduleRenewal(): void {
        if (!this.isAuthenticated) {
            return;
        }

        this.unscheduleRenewal();
        const expiresAt = this.expiresAt;

        const expiresIn$ = of(expiresAt.getTime()).pipe(
            mergeMap((expiresAt2) => {
                const now = Date.now();
                const afterSec = expiresAt2 - now - 10 * 1000; // before 10 sec
                this._logger.info(
                    `${new Date().toISOString()} Next token renew after ${
                        afterSec / 1000
                    } sec.`
                );
                // Use timer to track delay until expiration
                // to run the refresh at the proper time
                return timer(Math.max(1, afterSec));
            })
        );

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(() => {
            this.renewTokens();
            this.scheduleRenewal();
        });
    }

    public unscheduleRenewal(): void {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }

    public logout(): void {
        // clear
        this.clear();

        // Sign out of Firebase
        this.loggedInFirebase = false;
        this._fireAuth.auth.signOut();

        // Go back to the home route
        this._router.navigate([this.loginPage]);
    }

    public clear(): void {
        this._accessToken = '';
        this._idToken = '';

        this.unscheduleRenewal();
        this.unscheduleFirebaseRenewal();

        localStorage.clear();
        this.authData$.next(null);
    }

    private _getInfo(): Observable<User> {
        this.isBusyToFetchUserData = true;

        return combineLatest([
            this._iam.getInfo(),
            this.getFirebaseToken()
        ]).pipe(
            tap((x) => this._logger.info(`MY INFO`, x)),
            tap((x) =>
                localStorage.setItem('currentUser', JSON.stringify(x[0]))
            ),
            // tap(x => this.currentUserSubject.next(x[0])),
            tap((x) => {
                if (x[0]) {
                    this.scheduleRenewal();
                }

                if (x[1]) {
                    this._firebaseAuth(x[1]);
                }
            }),
            finalize(() => (this.isBusyToFetchUserData = false)),
            map((x) => x[0])
        );
    }

    public userHasScopes(scopes: Array<string>): boolean {
        return scopes.every((scope) => this.scopes.includes(scope));
    }

    public userHasRoles(roles: Array<string>): boolean {
        return roles.some((role) => this.roles.includes(role));
    }

    public hasRole = (role: string) => this.userHasRoles([role.trim()]);

    public async login(
        username: string,
        password: string,
        cb: any
    ): Promise<void> {
        const body = new URLSearchParams({
            grant_type: 'password',
            client_id: environment.identity.clientId,
            username: username,
            password: password,
            scope: 'market.api.debug market.api openid profile offline_access'
        });
        const promise = this.getAccessTokenFor(body, true);
        return promise
            .then((json) => {
                this.handleAuthentication();
                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    getFirebaseToken(): Observable<any> {
        // Prompt for login if no access token
        if (!this.isAuthenticated) {
            this.authorize();
        }

        return this._http.get(`${environment.progbonusApi}auth/firebase`, {
            headers: new HttpHeaders().set(
                'Authorization',
                `Bearer ${this.accessToken}`
            )
        });
    }

    private _firebaseAuth(tokenObj): void {
        this._fireAuth.auth
            .signInWithCustomToken(tokenObj.firebaseToken)
            .then((res) => {
                this.loggedInFirebase = true;
                // Schedule token renewal
                this.scheduleFirebaseRenewal();
                console.log('Successfully authenticated with Firebase!');
            })
            .catch((err) => {
                const errorCode = err.code;
                const errorMessage = err.message;
                console.error(
                    `${errorCode} Could not log into Firebase: ${errorMessage}`
                );
                this.loggedInFirebase = false;
            });
    }

    scheduleFirebaseRenewal(): void {
        // If user isn't authenticated, check for Firebase subscription
        // and unsubscribe, then return (don't schedule renewal)
        if (!this.loggedInFirebase) {
            if (this.firebaseSub) {
                this.firebaseSub.unsubscribe();
            }
            return;
        }
        // Unsubscribe from previous expiration observable
        this.unscheduleFirebaseRenewal();
        // Create and subscribe to expiration observable
        // Custom Firebase tokens minted by Firebase
        // expire after 3600 seconds (1 hour)
        const expiresAt = new Date().getTime() + 3600 * 1000;
        const expiresIn$ = of(expiresAt).pipe(
            mergeMap((expires) => {
                const now = Date.now();
                // Use timer to track delay until expiration
                // to run the refresh at the proper time
                return timer(Math.max(1, expires - now));
            })
        );

        this.refreshFirebaseSub = expiresIn$.subscribe(() => {
            console.log('Firebase token expired; fetching a new one');
            this.getFirebaseToken();
        });
    }

    unscheduleFirebaseRenewal(): void {
        if (this.refreshFirebaseSub) {
            this.refreshFirebaseSub.unsubscribe();
        }
    }

    isLoggedIn() {
        const user = localStorage.getItem(this.local_storage_key.current_user);
        if (user) {
            return true;
        }
        return false;
    }

    async getAccessTokenFor(body, isLogin = false) {
        const fetchPromise = fetch(
            environment.identity.idpAuthority + '/connect/token',
            {
                method: 'POST',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8',
                    'Access-Control-Allow-Origin': 'true'
                },
                body: body
            }
        );

        return fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                const data = this.parseJwt(json.access_token);
                data.roles = data.role.split(',').map((x) => x.toLowerCase());
                data.market = { companyId: data.tenant_id };

                this._accessToken = json.access_token;
                this._idToken = json.access_token;
                this._refreshToken = json.refresh_token;
                localStorage.setItem(
                    this.local_storage_key.current_user,
                    JSON.stringify(data)
                );
                localStorage.setItem(
                    this.local_storage_key.access_token,
                    json.access_token
                );
                localStorage.setItem(
                    this.local_storage_key.refresh_token,
                    json.refresh_token
                );
                localStorage.setItem(
                    this.local_storage_key.id_token,
                    json.access_token
                );
                localStorage.setItem(
                    this.local_storage_key.auth_result,
                    JSON.stringify(data)
                );
                if (isLogin) {
                    this._getUserDataRequest$.next(true);
                }
                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(
                    (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                )
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
}