import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
export let isDebugMode = !environment.production || localStorage['showLogging'];
const noop = () => undefined;
const ɵ0 = noop;
export class ConsoleLoggerService {
    get info() {
        if (isDebugMode) {
            return console.log.bind(console);
        }
        else {
            return noop;
        }
    }
    get warn() {
        if (isDebugMode) {
            return console.warn.bind(console);
        }
        else {
            return noop;
        }
    }
    get error() {
        if (isDebugMode) {
            return console.error.bind(console);
        }
        else {
            return noop;
        }
    }
    invokeConsoleMethod(type, args) {
        const logFn = () => console[type] || console.log || noop;
        logFn.apply(console, [args]);
    }
}
ConsoleLoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleLoggerService_Factory() { return new ConsoleLoggerService(); }, token: ConsoleLoggerService, providedIn: "root" });
export { ɵ0 };
