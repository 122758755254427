const ɵ0 = {
    TOOLBAR: {
        MyProfile: 'ملفي الشخسصي',
        Logout: 'تسجيل خروج'
    }
};
export const locale = {
    lang: 'ar',
    data: ɵ0
};
export { ɵ0 };
