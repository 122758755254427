import { Injectable } from '@angular/core';
import {
    CanActivate,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { LoggerService } from '@progbonus/logger/logger.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private readonly _auth: AuthService,
        private readonly _logger: LoggerService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this._auth.isAuthenticated) {
            return true;
        }
        console.log(`user is not authenticated!`);
        this._auth.logout();
        return false;
    }
}
