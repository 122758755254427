import { Injectable } from '@angular/core';
import { AuthService } from '@progbonus/auth/auth.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DisplayService {
    username: string;
    email: string;
    avatar: string;

    constructor(
        private readonly _auth: AuthService,
        private readonly _logger: LoggerService
    ) {
        this._auth.currentUser$
            .pipe(tap(u => this._logger.info(`Display for user:`, u)))
            .subscribe(u => {
                this.avatar = 'assets/images/avatars/profile.jpg';

                if (!u) {
                    this.username = null;
                    this.email = null;
                    return;
                }

                const username = u.username;

                const name = `${u.firstName || ''} ${u.lastName || ''}`.trim();

                const role = u.roles.some(x => x === 'seller' || x === 'cashier')
                    ? 'Продавец'
                    : u.roles.some(x => x === 'owner')
                    ? 'Владелец'
                    : null;

                this.username = name
                    ? name
                    : username
                    ? username
                    : role
                    ? role
                    : null;
                this.email = u.email;
            });
    }
}
