import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { environment } from 'environments/environment';

export let isDebugMode = !environment.production || localStorage['showLogging'];

const noop = (): any => undefined;

@Injectable({
    providedIn: 'root'
})
export class ConsoleLoggerService implements Logger {
    get info(): any {
        if (isDebugMode) {
            return console.log.bind(console);
        } else {
            return noop;
        }
    }

    get warn(): any {
        if (isDebugMode) {
            return console.warn.bind(console);
        } else {
            return noop;
        }
    }

    get error(): any {
        if (isDebugMode) {
            return console.error.bind(console);
        } else {
            return noop;
        }
    }

    invokeConsoleMethod(type: string, args?: any): void {
        const logFn = () => console[type] || console.log || noop;
        logFn.apply(console, [args]);
    }
}
