import { Router } from '@angular/router';
import { LoggerService } from '@progbonus/logger/logger.service';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../logger/logger.service";
export class RolesGuard {
    constructor(_auth, router, _logger) {
        this._auth = _auth;
        this.router = router;
        this._logger = _logger;
    }
    canActivate(route) {
        const roles = route.data.expectedRoles;
        if (!this._auth.userHasRoles(roles)) {
            this._logger.info(`User is not in required roles`, roles);
            this._auth.logout();
            return false;
        }
        return true;
    }
}
RolesGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesGuard_Factory() { return new RolesGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoggerService)); }, token: RolesGuard, providedIn: "root" });
