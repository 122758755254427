export const locale = {
    lang: 'ru',
    data: {
        NAV: {
            APPLICATIONS: 'Приложения',
            SAMPLE: {
                TITLE: 'Пример',
                BADGE: '15'
            },
            ARCHITECT: {
                TITLE: 'Архитектор',
                COUNTRIES: 'Страны',
                INSTAGRAM: 'Instagram'
            },
            HERO: {
                DASHBOARD: 'Dashboard',
                COMPANIES: 'Компании',
                FRANCHISEES: 'Франшизы',
                CITIES: 'Города',
                reports: 'Отчёты'
            },
            FRANCHISEE: {
                title: 'Франшиза',
                DASHBOARD: 'Dashboard',
                COMPANIES: 'Компании'
            },
            MARKET: {
                DASHBOARD: 'Главная',
                STORES: 'Магазины',
                SELLERS: 'Продавцы',
                CUSTOMERS: 'Покупатели',
                PURCHASES: 'Продажи',
                COUPONS: 'Купоны',
                PROMOTIONS: 'Акции',
                SMS: 'Смс-сообщения',
                EVENTS: 'События',
                BONUSESWRITEOFFS: 'Бонусы и списания',
                INSTAGRAM: 'Инстаграм',
                REPORTS: 'Отчеты',
                SETTINGS: 'Настройки',
                BONUSES: 'Бонусы'
            }
        }
    }
};
