import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import { tap, catchError } from 'rxjs/operators';
import { User } from '@progbonus/models';

@Injectable({
    providedIn: 'root'
})
export class IAmService extends BaseApiService {
    private readonly _myUrl = `${this.baseUrl}api/users/iam`;

    constructor(
        protected readonly http: HttpClient,
        private readonly _logger: LoggerService
    ) {
        super(http);
    }

    getInfo(): Observable<User> {
        this._logger.info(`Try get me...`);
        return this.http.get(this._myUrl).pipe(
            tap(x => this._logger.info(`I am`, x)),
            catchError(error => {
                this._logger.info(`I am with error`, error);
                return of(null);
            })
        );
    }
}
