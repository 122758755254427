export const locale = {
    lang: 'ru',
    data: {
        TOOLBAR: {
            MyProfile: 'Мой аккаунт',
            Logout: 'Выйти',
            user: 'Пользователь'
        }
    }
};
