export const locale = {
    lang: 'en',
    data: {
        TOOLBAR: {
            MyProfile: 'My Profile',
            Logout: 'Logout',
            user: 'User'
        }
    }
};
