import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggerService } from '@progbonus/logger/logger.service';
import { FirestoreService } from '../firestore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../firestore.service";
import * as i3 from "../../logger/logger.service";
export class CurrentMarketService {
    constructor(_http, _afs, _logger) {
        this._http = _http;
        this._afs = _afs;
        this._logger = _logger;
        this.onSellerStoreChanged = new Subject();
        this._currentMarketSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(this._currentMarketKey)));
        this.currentMarket$ = this._currentMarketSubject.asObservable();
        this._firestoreCompany$ = this.currentMarket$.subscribe(x => x
            ? this._afs
                .doc$(`companies/${x.companyCode}`)
                .subscribe(company => {
                this._logger.info(`Company from firestore is`, company);
            })
            : null);
    }
    get _currentMarketKey() {
        return 'currentMarket';
    }
    get _currentStoreKey() {
        return 'currentStoreId';
    }
    get id() {
        return this.currentMarketValue
            ? this.currentMarketValue.companyId
            : null;
    }
    get code() {
        return this.currentMarketValue
            ? this.currentMarketValue.companyCode
            : null;
    }
    get tz() {
        return this.currentMarketValue ? this.currentMarketValue.cityTz : null;
    }
    get now() {
        return this.currentMarketValue ? new Date() : null;
    }
    get today() {
        return this.now ? new Date(this.now.toDateString()) : null;
    }
    get phoneCode() {
        return this.currentMarketValue
            ? this.currentMarketValue.phoneCode
            : null;
    }
    get hasUtmSources() {
        return false;
    }
    get currentMarketValue() {
        return this._currentMarketSubject.value;
    }
    load(market) {
        if (this.id === market.companyId) {
            return;
        }
        this.clear();
        localStorage.setItem(this._currentMarketKey, JSON.stringify(market));
        this._currentMarketSubject.next(market);
    }
    get storeId() {
        if (!this._storeId) {
            if (+localStorage[this._currentStoreKey]) {
                this.setStoreId(+localStorage[this._currentStoreKey]);
            }
        }
        return this._storeId;
    }
    setStoreId(storeId) {
        localStorage[this._currentStoreKey] = storeId;
        this._storeId = storeId;
        this.onSellerStoreChanged.next(this.storeId);
    }
    setStore(store) {
        this.setStoreId(store ? store.storeId : null);
    }
    clear() {
        localStorage.removeItem(this._currentMarketKey);
        localStorage.removeItem(this._currentStoreKey);
        this._currentMarketSubject.next(null);
    }
}
CurrentMarketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentMarketService_Factory() { return new CurrentMarketService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FirestoreService), i0.ɵɵinject(i3.LoggerService)); }, token: CurrentMarketService, providedIn: "root" });
