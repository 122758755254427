import { AuthService } from './auth.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../logger/logger.service";
export class AuthGuard {
    constructor(_auth, _logger) {
        this._auth = _auth;
        this._logger = _logger;
    }
    canActivate(route, state) {
        if (this._auth.isAuthenticated) {
            return true;
        }
        console.log(`user is not authenticated!`);
        this._auth.logout();
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LoggerService)); }, token: AuthGuard, providedIn: "root" });
