import { Routes } from '@angular/router';
import { AuthGuard } from '@progbonus/auth/auth.guard';
import { RolesGuard } from '@progbonus/auth/role.guard';
import { LogoutComponent } from './main/logout/logout.component';

export const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'callback'
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./main/login/login.module').then(x => x.LoginModule)
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'callback',
        loadChildren: () =>
            import('./main/lock/lock.module').then(x => x.LockModule)
    },
    {
        path: 'user/profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./main/profile/profile.module').then(x => x.ProfileModule)
    },
    {
        path: 'market', // <-- prefix in Navs
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./main/market/market.module').then(x => x.MarketModule)
    },
    {
        path: 'markets/:companyId', // <-- prefix in Navs
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./main/market/market.module').then(x => x.MarketModule)
    },
    {
        path: 'hero',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: () =>
            import('./main/hero/hero.module').then(x => x.HeroModule),
        data: { expectedRoles: ['hero', 'manager', 'admin'] }
    },
    {
        path: 'architect',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: () =>
            import('./main/architect/architect.module').then(
                x => x.ArchitectModule
            ),
        data: { expectedRoles: ['architect'] }
    },
    {
        path: 'franchisee',
        canActivate: [AuthGuard, RolesGuard],
        loadChildren: () =>
            import('./main/franchisee/franchisee.module').then(
                x => x.FranchiseeModule
            ),
        data: { expectedRoles: ['franchisee'] }
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];
