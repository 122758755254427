import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { ProgBonusPipesModule } from './pipes/pipes.module';
import { MatIconModule, MatProgressBarModule } from '@angular/material';
import {
    BirthInputDirective,
    GenderInputComponent,
    PhoneNumberInputDirective,
    CardNumberInputDirective,
    DisableControlDirective,
    AutofocusDirective
} from './directives';
import { PurchaseTypeComponent } from './components/purchase-type/purchase-type.component';
import { BonusTypeBadgeComponent } from './components/bonus-type-badge/bonus-type-badge.component';
import { SmartButtonModule } from './components/smart-button/smart-button.module';

@NgModule({
    declarations: [
        BirthInputDirective,
        GenderInputComponent,
        PhoneNumberInputDirective,
        CardNumberInputDirective,
        DisableControlDirective,
        AutofocusDirective,

        PurchaseTypeComponent,

        BonusTypeBadgeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatIconModule,
        MatProgressBarModule,

        FlexLayoutModule,

        ProgBonusPipesModule,
        SmartButtonModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        BirthInputDirective,
        GenderInputComponent,
        PhoneNumberInputDirective,
        CardNumberInputDirective,
        DisableControlDirective,
        AutofocusDirective,

        ProgBonusPipesModule,
        SmartButtonModule,

        PurchaseTypeComponent,
        BonusTypeBadgeComponent
    ],
    entryComponents: []
})
export class ProgBonusSharedModule {}
