import { NgModule } from '@angular/core';
import { BirthdayPipe } from './birthday.pipe';
import { CurrentStoreDatePipe } from './current-store-date.pipe';
import { GenderPipe } from './gender.pipe';
import { RoundFloatPipe } from './round-float.pipe';
import { FullNamePipe } from './full-name.pipe';

@NgModule({
    declarations: [
        BirthdayPipe,
        CurrentStoreDatePipe,
        GenderPipe,
        RoundFloatPipe,
        FullNamePipe
    ],
    imports: [],
    exports: [
        BirthdayPipe,
        CurrentStoreDatePipe,
        GenderPipe,
        RoundFloatPipe,
        FullNamePipe
    ]
})
export class ProgBonusPipesModule {}
