import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { mergeMap, switchMap, tap, finalize, filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@progbonus/logger/logger.service';
import { BehaviorSubject, of, timer, combineLatest } from 'rxjs';
import { IAmService } from '@progbonus/services/iam.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/fire/auth";
import * as i4 from "../services/iam.service";
import * as i5 from "@auth0/angular-jwt/src/jwthelper.service";
import * as i6 from "../logger/logger.service";
window.global = window;
export class AuthService {
    constructor(_router, _http, _fireAuth, _iam, _jwtHelper, _logger) {
        this._router = _router;
        this._http = _http;
        this._fireAuth = _fireAuth;
        this._iam = _iam;
        this._jwtHelper = _jwtHelper;
        this._logger = _logger;
        this.loginPage = '/login';
        this.loginUniversal = '/login/universal';
        this.profilePage = '/user/profile';
        this.local_storage_key = {
            current_user: 'current_user',
            id_token: 'id_token',
            access_token: 'access_token',
            auth_result: 'auth_result',
            refresh_token: 'refresh_token'
        };
        this._getUserDataRequest$ = new BehaviorSubject(false);
        this.hasRole = (role) => this.userHasRoles([role.trim()]);
        this._idToken = localStorage.getItem('id_token');
        this._accessToken = tokenGetter();
        this._refreshToken = localStorage.getItem(this.local_storage_key.refresh_token);
        this.authData$ = new BehaviorSubject(null);
        this._getUserData$ = this._getUserDataRequest$
            .pipe(filter((x) => x), switchMap((x) => this._getInfo()))
            .subscribe((x) => {
            this._logger.info(`subscribed on user`);
            this.authData$.next({ user: x });
        });
        this.currentUser$ = this.authData$.pipe(tap((x) => (this._currUser = x ? x.user : null)), map((x) => (x ? x.user : null)));
    }
    // private _isAuthenticated = new BehaviorSubject(false);
    // isAuthenticated$: Observable<boolean>;
    get isAuthenticated() {
        return !this._jwtHelper.isTokenExpired(this.accessToken);
    }
    get currentUserValue() {
        return this._currUser;
    }
    get userId() {
        return this.currentUserValue ? this.currentUserValue.id : null;
    }
    get avatar() {
        return 'assets/images/avatars/profile.jpg';
    }
    get email() {
        return this.currentUserValue ? this.currentUserValue.email : '';
    }
    get displayName() {
        const name = this.currentUserValue
            ? `${this.currentUserValue.firstName || ''} ${this.currentUserValue.lastName || ''}`.trim()
            : '';
        const role = this.currentUserValue
            ? this.currentUserValue.roles.some((x) => x === 'cashier' || x === 'seller')
                ? 'Продавец'
                : null
            : null;
        return name || role;
    }
    get scopes() {
        return this.currentUserValue ? this.currentUserValue.scope : [];
    }
    get roles() {
        return this.currentUserValue && this.currentUserValue.roles
            ? this.currentUserValue.roles
            : [];
    }
    get expiresAt() {
        return this._jwtHelper.getTokenExpirationDate(this.accessToken);
    }
    get isArchitector() {
        return this.hasRole('architect');
    }
    get isHero() {
        return this.hasRole('hero') || this.hasRole('manager');
    }
    get isSeller() {
        return this.hasRole('cashier') || this.hasRole('seller');
    }
    get isOwner() {
        return this.hasRole('owner');
    }
    get isOwnerOrSeller() {
        return this.isOwner || this.isSeller;
    }
    get isFranchisee() {
        return this.hasRole('franchisee');
    }
    get _localUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
    get accessToken() {
        return this._accessToken;
    }
    get idToken() {
        return this._idToken;
    }
    get refreshToken() {
        return this._refreshToken;
    }
    authorize() {
    }
    getProfile(cb) {
        if (!this._accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }
    }
    handleAuthentication() {
        this._logger.info(`handleAuthentication...`);
        let isLoggedIn = this.isLoggedIn();
        if (!isLoggedIn) {
            this.authData$.next({ error: 'error' });
            const url = location.href.indexOf('/login/universal') > -1
                ? this.loginUniversal
                : this.loginPage;
            this._router.navigate([url]);
        }
    }
    _localLogin(authResult) {
        // Set the time that the access token will expire at
        const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this._accessToken = authResult.accessToken;
        this._idToken = authResult.idToken;
        // localStorage.setItem('expires_at', expiresAt.toString());
        // if (this._isDebug) {
        localStorage.setItem('access_token', this.accessToken);
        localStorage.setItem('id_token', this.idToken);
        // }
    }
    renewTokens(isUpdateUser = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const body = new URLSearchParams({
                client_id: environment.identity.clientId,
                grant_type: 'refresh_token',
                refresh_token: this._refreshToken,
                scope: 'market.api.debug market.api openid profile offline_access'
            });
            yield this.getAccessTokenFor(body, isUpdateUser);
        });
    }
    scheduleRenewal() {
        if (!this.isAuthenticated) {
            return;
        }
        this.unscheduleRenewal();
        const expiresAt = this.expiresAt;
        const expiresIn$ = of(expiresAt.getTime()).pipe(mergeMap((expiresAt2) => {
            const now = Date.now();
            const afterSec = expiresAt2 - now - 10 * 1000; // before 10 sec
            this._logger.info(`${new Date().toISOString()} Next token renew after ${afterSec / 1000} sec.`);
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, afterSec));
        }));
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(() => {
            this.renewTokens();
            this.scheduleRenewal();
        });
    }
    unscheduleRenewal() {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }
    logout() {
        // clear
        this.clear();
        // Sign out of Firebase
        this.loggedInFirebase = false;
        this._fireAuth.auth.signOut();
        // Go back to the home route
        this._router.navigate([this.loginPage]);
    }
    clear() {
        this._accessToken = '';
        this._idToken = '';
        this.unscheduleRenewal();
        this.unscheduleFirebaseRenewal();
        localStorage.clear();
        this.authData$.next(null);
    }
    _getInfo() {
        this.isBusyToFetchUserData = true;
        return combineLatest([
            this._iam.getInfo(),
            this.getFirebaseToken()
        ]).pipe(tap((x) => this._logger.info(`MY INFO`, x)), tap((x) => localStorage.setItem('currentUser', JSON.stringify(x[0]))), 
        // tap(x => this.currentUserSubject.next(x[0])),
        tap((x) => {
            if (x[0]) {
                this.scheduleRenewal();
            }
            if (x[1]) {
                this._firebaseAuth(x[1]);
            }
        }), finalize(() => (this.isBusyToFetchUserData = false)), map((x) => x[0]));
    }
    userHasScopes(scopes) {
        return scopes.every((scope) => this.scopes.includes(scope));
    }
    userHasRoles(roles) {
        return roles.some((role) => this.roles.includes(role));
    }
    login(username, password, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const body = new URLSearchParams({
                grant_type: 'password',
                client_id: environment.identity.clientId,
                username: username,
                password: password,
                scope: 'market.api.debug market.api openid profile offline_access'
            });
            const promise = this.getAccessTokenFor(body, true);
            return promise
                .then((json) => {
                this.handleAuthentication();
                return Promise.resolve();
            })
                .catch((error) => {
                return Promise.reject(error);
            });
        });
    }
    getFirebaseToken() {
        // Prompt for login if no access token
        if (!this.isAuthenticated) {
            this.authorize();
        }
        return this._http.get(`${environment.progbonusApi}auth/firebase`, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`)
        });
    }
    _firebaseAuth(tokenObj) {
        this._fireAuth.auth
            .signInWithCustomToken(tokenObj.firebaseToken)
            .then((res) => {
            this.loggedInFirebase = true;
            // Schedule token renewal
            this.scheduleFirebaseRenewal();
            console.log('Successfully authenticated with Firebase!');
        })
            .catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            console.error(`${errorCode} Could not log into Firebase: ${errorMessage}`);
            this.loggedInFirebase = false;
        });
    }
    scheduleFirebaseRenewal() {
        // If user isn't authenticated, check for Firebase subscription
        // and unsubscribe, then return (don't schedule renewal)
        if (!this.loggedInFirebase) {
            if (this.firebaseSub) {
                this.firebaseSub.unsubscribe();
            }
            return;
        }
        // Unsubscribe from previous expiration observable
        this.unscheduleFirebaseRenewal();
        // Create and subscribe to expiration observable
        // Custom Firebase tokens minted by Firebase
        // expire after 3600 seconds (1 hour)
        const expiresAt = new Date().getTime() + 3600 * 1000;
        const expiresIn$ = of(expiresAt).pipe(mergeMap((expires) => {
            const now = Date.now();
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, expires - now));
        }));
        this.refreshFirebaseSub = expiresIn$.subscribe(() => {
            console.log('Firebase token expired; fetching a new one');
            this.getFirebaseToken();
        });
    }
    unscheduleFirebaseRenewal() {
        if (this.refreshFirebaseSub) {
            this.refreshFirebaseSub.unsubscribe();
        }
    }
    isLoggedIn() {
        const user = localStorage.getItem(this.local_storage_key.current_user);
        if (user) {
            return true;
        }
        return false;
    }
    getAccessTokenFor(body, isLogin = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const fetchPromise = fetch(environment.identity.idpAuthority + '/connect/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Access-Control-Allow-Origin': 'true'
                },
                body: body
            });
            return fetchPromise
                .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
                .then((json) => {
                const data = this.parseJwt(json.access_token);
                data.roles = data.role.split(',').map((x) => x.toLowerCase());
                data.market = { companyId: data.tenant_id };
                this._accessToken = json.access_token;
                this._idToken = json.access_token;
                this._refreshToken = json.refresh_token;
                localStorage.setItem(this.local_storage_key.current_user, JSON.stringify(data));
                localStorage.setItem(this.local_storage_key.access_token, json.access_token);
                localStorage.setItem(this.local_storage_key.refresh_token, json.refresh_token);
                localStorage.setItem(this.local_storage_key.id_token, json.access_token);
                localStorage.setItem(this.local_storage_key.auth_result, JSON.stringify(data));
                if (isLogin) {
                    this._getUserDataRequest$.next(true);
                }
                return Promise.resolve();
            })
                .catch((error) => {
                return Promise.reject(error);
            });
        });
    }
    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''));
        return JSON.parse(jsonPayload);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AngularFireAuth), i0.ɵɵinject(i4.IAmService), i0.ɵɵinject(i5.JwtHelperService), i0.ɵɵinject(i6.LoggerService)); }, token: AuthService, providedIn: "root" });
