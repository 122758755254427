import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class FirestoreService {
    constructor(afs) {
        this.afs = afs;
    }
    /// *********
    /// Get a Reference
    /// *********
    col(ref, queryFn) {
        return typeof ref === 'string'
            ? this.afs.collection(ref, queryFn)
            : ref;
    }
    doc(ref) {
        return typeof ref === 'string' ? this.afs.doc(ref) : ref;
    }
    /// *********
    /// GET DATA
    /// *********
    doc$(ref) {
        return this.doc(ref)
            .snapshotChanges()
            .pipe(map(doc => doc.payload.data()));
    }
    col$(ref, queryFn) {
        return this.col(ref, queryFn)
            .snapshotChanges()
            .pipe(map(docs => docs.map(x => x.payload.doc.data())));
    }
}
FirestoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirestoreService_Factory() { return new FirestoreService(i0.ɵɵinject(i1.AngularFirestore)); }, token: FirestoreService, providedIn: "root" });
