import { HttpHeaders } from '@angular/common/http';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import 'hammerjs';
import { LoggerService } from '@progbonus/logger/logger.service';
import { environment } from 'environments/environment';
import { ODataConfiguration } from 'angular-odata-es5';
import { Apollo } from 'apollo-angular';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
// locale
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { registerLocaleData } from '@angular/common';
import { CurrentMarketService } from '@progbonus/services/market/current-market.service';
import { AuthService } from '@progbonus/auth/auth.service';
export function tokenGetter() {
    return localStorage.getItem('access_token');
}
registerLocaleData(localeRu, localeRuExtra);
const ɵ0 = environment.localeId, ɵ1 = MAT_MOMENT_DATE_FORMATS, ɵ2 = {}, ɵ3 = (market) => {
    const config = new ODataConfiguration();
    config.baseUrl = `${environment.progbonusApi}odata`;
    let headers = new HttpHeaders();
    // headers = headers.append('Accept', 'application/json');
    headers = headers.append('X-Market', market.id);
    // Set some new `customRequestOptions` here as an example
    config.defaultRequestOptions.headers = headers;
    return config;
};
export class AppModule {
    constructor(apollo, auth, market, _logger) {
        this._logger = _logger;
        const httpLink = createHttpLink({
            uri: environment.graphQlUrl
        });
        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const token = auth.accessToken;
            // return the headers to the context so httpLink can read them
            return {
                headers: Object.assign({}, headers, { authorization: token ? `Bearer ${token}` : '', ['X-Market']: market.id })
            };
        });
        apollo.create({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({ addTypename: false })
        });
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
