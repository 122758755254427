import { Injectable } from '@angular/core';
import { AuthService } from '@progbonus/auth/auth.service';
import { CurrentMarketService } from './market/current-market.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LoggerService } from '@progbonus/logger/logger.service';
import { NavHelper, buildNavs } from 'app/navigation/navigation';
import { User } from '@progbonus/models/user.model';
import { replaceAll } from '@progbonus/helpers/string.helpers';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

export class MarketReportLinks {
    constructor(private readonly _base: string) {}

    get self(): string {
        return this._base + '/reports';
    }

    get customers(): string {
        return `${this.self}/customers`;
    }

    get instagram(): string {
        return `${this.self}/instagram`;
    }

    get main(): string {
        return `${this.self}/main`;
    }

    get birthday(): string {
        return `${this.self}/birthday`;
    }

    get registration(): string {
        return `${this.self}/registration`;
    }

    get bonus(): string {
        return `${this.self}/bonus`;
    }

    get purchases(): string {
        return `${this.self}/purchases`;
    }

    get sms(): string {
        return `${this.self}/sms`;
    }
}

export interface MarketLinks {
    reports: MarketReportLinks;
}

@Injectable({
    providedIn: 'root'
})
export class AppNavService {
    private readonly emptyKey = 'empty';

    private _navigation: any;

    private _navKeyForRole: string;

    private _currentMarketKey: string;
    public get currentMarketKey(): string {
        return this._currentMarketKey;
    }

    private _cached: any;

    private _navUpdateSub: Subscription;

    constructor(
        private readonly _auth: AuthService,
        private readonly _currentCompany: CurrentMarketService,
        private readonly _fuseNavigationService: FuseNavigationService,
        private readonly _router: Router,
        private readonly _logger: LoggerService
    ) {
        // Get default navigation
        this._navigation = [];

        // Register the navigation to the service
        this._fuseNavigationService.register(this.emptyKey, this._navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation(this.emptyKey);

        this._init();
    }

    start(): void {
        // dumpy
    }

    private _userWithoutMarket(user: User): void {
        if (!user) {
            this._logger.info(`navigation for null user can't be update`);
            return;
        }

        const navKey =
            this._navKeyForRole ||
            NavHelper.getNavKeyForRoles(this._auth.roles);

        const navs = buildNavs(this._auth.roles);

        if (!this._navKeyForRole) {
            // Register the new navigation
            this._fuseNavigationService.register(navKey, navs);
        }

        // Set the current navigation
        this._fuseNavigationService.setCurrentNavigation(navKey);

        this._navKeyForRole = navKey;

        this._logger.info(
            `App Navs has been initialized as ${navKey} for`,
            this._auth.roles
        );
    }

    private _init(): void {
        this._cached = {};

        this._navUpdateSub = combineLatest([
            this._auth.currentUser$,
            this._currentCompany.currentMarket$
        ]).subscribe(arr => {
            const user = arr[0];
            const market = arr[1];

            if (!user) {
                // Set the current navigation
                this._fuseNavigationService.setCurrentNavigation(this.emptyKey);
                return;
            }

            if (!market) {
                this._userWithoutMarket(this._auth.currentUserValue);
                return;
            }

            const roles = [...this._auth.roles];

            if (
                this._auth.isArchitector ||
                this._auth.isHero ||
                this._auth.isFranchisee
            ) {
                roles.push('owner');
            }

            console.log(`MARKET FOR NAVS`, market);

            const navs = buildNavs(roles, market);

            const key = `market-${market.companyCode}`;
            this._currentMarketKey = key;

            if (!this._fuseNavigationService.getNavigation(key)) {
                this._fuseNavigationService.register(key, navs);
            }

            this._fuseNavigationService.setCurrentNavigation(key);

            this._logger.info(
                `App Navs has been initialized as ${key} with market for`,
                this._auth.roles
            );
        });
    }

    clearMarket(): void {
        if (!this._auth.isOwnerOrSeller) {
            this._userWithoutMarket(this._auth.currentUserValue);
            this._cached = {};
        }
    }

    composeMarketNavForUser(marketId: string = null, args: any[] = []): string {
        let url = this._urlOfMarket(marketId);

        if (!args.length) {
            return url;
        }

        for (const a of args) {
            url += `/${replaceAll(a, '/', '')}`;
        }

        return url;
    }

    private _urlOfMarket(marketId: string = null): string {
        return this._auth.isOwnerOrSeller ? '/market' : `/markets/${marketId}`;
    }

    market(marketId: string): MarketLinks {
        if (this._cached[marketId]) {
            return this._cached[marketId];
        }

        const url = this._urlOfMarket(marketId);
        const urls = {
            reports: new MarketReportLinks(url)
        };

        this._cached[marketId] = urls;
        return this._cached[marketId];
    }

    navigateToMarket(
        marketId: string,
        params: any[],
        extras: any = null
    ): void {
        const url = this.composeMarketNavForUser(marketId, params);
        // this._logger.info(`>>> url has been built: ${url}`);
        this._router.navigate([url, extras || {}]);
    }

    navigate(url: string, extras: any = null): void {
        // const navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         firstname: 'Nic',
        //         lastname: 'Raboy'
        //     }
        // };

        this._router.navigate([url]);
    }
}
