import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {
    constructor(public readonly afs: AngularFirestore) {}

    /// *********
    /// Get a Reference
    /// *********

    col<T>(
        ref: CollectionPredicate<T>,
        queryFn?
    ): AngularFirestoreCollection<T> {
        return typeof ref === 'string'
            ? this.afs.collection<T>(ref, queryFn)
            : ref;
    }

    doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
        return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
    }

    /// *********
    /// GET DATA
    /// *********

    doc$<T>(ref: DocPredicate<T>): Observable<T> {
        return this.doc(ref)
            .snapshotChanges()
            .pipe(map(doc => doc.payload.data() as T));
    }

    col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
        return this.col(ref, queryFn)
            .snapshotChanges()
            .pipe(map(docs => docs.map(x => x.payload.doc.data() as T)));
    }
}
