export const environment = {
    production: true,
    hmr: false,
    localeId: 'ru',
    debugLogin: null,
    appSettings: {
        waitCallback: 0
    },
    progbonusApi: 'https://marketapi-beta.bonusgroups.ru/',
    instagramApi: 'https://instagram.bonusgroups.ru/',
    reportsApi: 'https://reports.bonusgroups.ru/',
    graphQlUrl: 'https://marketapi-beta.bonusgroups.ru/graphql',
    auth0: {
        clientID: 'QsqmI7Ncq6eazT53LUFLsFHn3UiU76XO',
        domain: 'bonusgroups.auth0.com',
        responseType: 'token id_token',
        audience: 'https://store.progbonus.ru',
        redirectUri: 'https://market.bonusgroups.ru/callback',
        scope: 'openid profile'
    },
    firebase: {
        apiKey: 'AIzaSyDwubYXWIo5U2Y2JGW42YqvwkzYY0-4SsQ',
        authDomain: 'bonusgroups-5100c.firebaseapp.com',
        databaseURL: 'https://bonusgroups-5100c.firebaseio.com',
        projectId: 'bonusgroups-5100c',
        storageBucket: 'bonusgroups-5100c.appspot.com',
        messagingSenderId: '956509273365'
    },
    identity: {
        clientRoot: "https://market-stagging.bonusgroups.ru",
        idpAuthority: "https://auth.bonusgroups.ru",
        clientId: "angular-client-password-2",
        scope: "openid profile market.api",
        response_type: "code",
    },
};
