import { NgModule } from '@angular/core';
import { SmartButtonComponent } from './smart-button.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
} from '@angular/material';

@NgModule({
    declarations: [SmartButtonComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    exports: [SmartButtonComponent]
})
export class SmartButtonModule {}
