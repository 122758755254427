import { FuseNavigation } from '@fuse/types';
import { Market } from '@progbonus/models/market.model';

export const isSuperAdmin = (roles: string[]): boolean => {
    return (roles.map(x => x.toLowerCase()).some(x => x === 'architect' || x === 'superadmin'));
}

export const isAdmin = (roles: string[]): boolean => {
    return (roles.map(x => x.toLowerCase()).some(x => x === 'hero' || x === 'admin' || x === 'manager'));
}

export const isFranchisee = (roles: string[]): boolean => {
    return (roles.map(x => x.toLowerCase()).some(x => x === 'franchisee'));
}

export const isOwner = (roles: string[]): boolean => {
    return (roles.map(x => x.toLowerCase()).some(x => x === 'owner'));
}

export const isCashier = (roles: string[]): boolean => {
    return (roles.map(x => x.toLowerCase()).some(x => x === 'seller' || x === 'cashier'));
}

export class NavHelper {
    static getNavKeyForRoles(roles: string[]): string {
        if (isSuperAdmin(roles)) {
            return 'architect-nav';
        } else if (isAdmin(roles)) {
            return 'hero-nav';
        } else if (isFranchisee(roles)) {
            return 'franchisee-nav';
        } else if (isOwner(roles)) {
            return 'owner-nav';
        } else if (isCashier(roles)) {
            return 'seller-nav';
        }

        return 'empty';
    }
}

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'sample',
                title: 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'email',
                url: '/sample',
                badge: {
                    title: '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg: '#F44336',
                    fg: '#FFFFFF'
                }
            }
        ]
    }
];

export const buildNavs = (roles: string[], market: Market = null): any[] => {
    if (!roles || !roles.length) {
        return [];
    }

    const arr: any[] = [];
    for (const role of roles) {
        if (role === 'architect' || role === 'superadmin') {
            architectNavs.forEach(x => arr.push(x));
        } else if (role === 'franchisee') {
            franchiseeNavs.forEach(x => arr.push(x));
        } else if (role === 'owner') {
            if (
                roles.some(
                    x => x === 'hero' || x === 'franchisee' || x === 'architect' || x === 'admin' || x === 'superadmin' || x === 'manager'
                )
            ) {
                buildMarketNavsForNonSellers(market).forEach(x => arr.push(x));
            } else {
                getSellerNavs(market, role).forEach(x => arr.push(x));
            }
        } else if (role === 'seller' || role === 'cashier') {
            getSellerNavs(market, role).forEach(x => arr.push(x));
        } else if (role === 'hero' || role === 'admin' || role === 'manager') {
            heroNavs.forEach(x => arr.push(x));
        }
    }

    return arr;
};

const getSellerNavs = (market: Market, role: string) => {
    return filteCompanyLinksFor(
        buildMarketNavs(market, role === 'seller' || role === 'owner' || role === 'cashier'),
        role
    );
};

const filteCompanyLinksFor = (links: any[], role: string) => {
    const res = links.filter(link => {
        if (
            link &&
            link.visableFor &&
            link.visableFor.some((x: string) => x === role)
        ) {
            if (link.children) {
                link.children = filteCompanyLinksFor(link.children, role);
            }
            return link;
        }
    });

    return res;
};

export const buildMarketNavsForNonSellers = (market: Market): any[] => {
    return buildMarketNavs(market, false);
};

const buildMarketNavs = (
    market: Market,
    hideMarketId: boolean = false
): any[] => {
    if (!market) {
        return [];
    }

    const marketId = market.companyId;
    const prefix = `/market${!hideMarketId ? `s/${marketId}` : ''}`;

    return [
        {
            id: 'market',
            title: market.name || 'Market',
            // translate: 'NAV.APPLICATIONS',
            type: 'group',
            visableFor: ['seller', 'owner', 'cashier'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Главная',
                    translate: 'NAV.MARKET.DASHBOARD',
                    type: 'item',
                    icon: 'dashboard',
                    url: `${prefix}/dashboard`,
                    visableFor: ['owner']
                },
                {
                    id: 'stores',
                    title: 'Магазины',
                    translate: 'NAV.MARKET.STORES',
                    type: 'item',
                    icon: 'store',
                    url: `${prefix}/stores`,
                    visableFor: ['owner']
                },
                {
                    id: 'sellers',
                    title: 'Продавцы',
                    translate: 'NAV.MARKET.SELLERS',
                    type: 'item',
                    icon: 'supervisor_account',
                    url: `${prefix}/sellers`,
                    visableFor: ['owner']
                },
                {
                    id: 'customers',
                    title: 'Покупатели',
                    translate: 'NAV.MARKET.CUSTOMERS',
                    type: 'item',
                    icon: 'people',
                    url: `${prefix}/customers`,
                    visableFor: ['owner', 'seller', 'cashier'],
                    exactMatch: true
                },
                {
                    id: 'purchase',
                    title: 'Продажи',
                    translate: 'NAV.MARKET.PURCHASES',
                    type: 'item',
                    icon: 'shopping_cart',
                    url: `${prefix}/purchases`,
                    visableFor: ['owner', 'seller', 'cashier']
                },
                {
                    id: 'bonuses',
                    title: 'bonuses',
                    translate: 'NAV.MARKET.BONUSES',
                    type: 'item',
                    icon: 'apps',
                    url: `${prefix}/bonuses`,
                    visableFor: ['owner']
                },
                // {
                //     id: 'coupons',
                //     title: 'Купоны',
                //     translate: 'NAV.MARKET.COUPONS',
                //     type: 'item',
                //     icon: 'apps',
                //     url: `${prefix}/coupons`,
                //     visableFor: ['owner']
                // },
                {
                    id: 'promotions',
                    title: 'Акции',
                    translate: 'NAV.MARKET.PROMOTIONS',
                    type: 'item',
                    icon: 'apps',
                    url: `${prefix}/promotions`,
                    visableFor: ['owner']
                },
                {
                    id: 'sms',
                    title: 'Смс Сообщения',
                    translate: 'NAV.MARKET.SMS',
                    type: 'item',
                    icon: 'message',
                    url: `${prefix}/sms`,
                    visableFor: ['owner']
                },
                // {
                //     id: 'bonuses-writeoffs',
                //     title: 'Бонусы и списания',
                //     translate: 'NAV.MARKET.BONUSESWRITEOFFS',
                //     type: 'item',
                //     icon: 'apps',
                //     url: `${prefix}/bonuses-writeoffs`,
                //     visableFor: ['owner']
                // },
                // // {
                // //     'id': 'events',
                // //     'title': 'События',
                // //     'type': 'collapse',
                // //     'icon': 'apps',
                // //     'visableFor': ['owner'],
                // //     'children': [
                // //         {
                // //             'id': 'event-list',
                // //             'title': 'Список',
                // //             'type': 'item',
                // //             'icon': 'apps',
                // //             'url': `${prefix}/${companyId}/store/events`,
                // //             'visableFor': ['owner'],
                // //             'exactMatch': true
                // //         },
                // //         {
                // //             'id': 'event-calendar',
                // //             'title': 'Календарь',
                // //             'type': 'item',
                // //             'icon': 'apps',
                // //             'url': `${prefix}/${companyId}/store/events/calendar`,
                // //             'visableFor': ['owner'],
                // //             'exactMatch': true
                // //         }
                // //     ]
                // // },
                // {
                //     id: 'instagram',
                //     title: 'Instagram',
                //     translate: 'NAV.MARKET.INSTAGRAM',
                //     type: 'item',
                //     icon: 'apps',
                //     url: `${prefix}/instagram`,
                //     visableFor: ['owner']
                // },
                {
                    id: 'reports',
                    title: 'Отчеты',
                    translate: 'NAV.MARKET.REPORTS',
                    type: 'item',
                    icon: 'assignment',
                    url: `${prefix}/reports`,
                    visableFor: ['owner']
                },
                {
                    id: 'settings',
                    title: 'Настройки',
                    translate: 'NAV.MARKET.SETTINGS',
                    type: 'item',
                    icon: 'settings',
                    url: `${prefix}/settings`,
                    visableFor: ['owner']
                }
            ]
        }
    ];
};

const architectNavs = [
    {
        id: 'architect',
        title: 'Architect',
        translate: 'NAV.ARCHITECT.TITLE',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'countries',
                title: 'Countries',
                translate: 'NAV.ARCHITECT.COUNTRIES',
                type: 'item',
                icon: 'store',
                url: '/architect/countries'
            },
            {
                id: 'instagram',
                title: 'Instagram',
                translate: 'NAV.ARCHITECT.INSTAGRAM',
                type: 'item',
                icon: 'store',
                url: '/architect/instagram'
            }
            // {
            //   id: 'map',
            //   title: 'Map',
            //   type: 'item',
            //   icon: 'store',
            //   url: '/hero/map'
            // }
        ]
    }
];

const franchiseeNavs = [
    {
        id: 'franchisee',
        title: 'Franchisee',
        translate: 'NAV.FRANCHISEE.title',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'NAV.FRANCHISEE.DASHBOARD',
                type: 'item',
                icon: 'dashboard',
                url: '/franchisee/dashboard'
            },
            {
                id: 'companies',
                title: 'Мои компании',
                translate: 'NAV.FRANCHISEE.COMPANIES',
                type: 'item',
                icon: 'store',
                url: '/franchisee/companies'
            }
        ]
    }
];

const heroNavs = [
    {
        id: 'hero',
        title: 'Hero',
        type: 'group',
        icon: 'apps',
        children: [
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     translate: 'NAV.HERO.DASHBOARD',
            //     type: 'item',
            //     icon: 'dashboard',
            //     url: '/hero/dashboard'
            // },
            {
                id: 'companies',
                title: 'Компании',
                translate: 'NAV.HERO.COMPANIES',
                type: 'item',
                icon: 'store',
                url: '/hero/companies'
            },
            // {
            //     id: 'franchisees',
            //     title: 'Франшизы',
            //     translate: 'NAV.HERO.FRANCHISEES',
            //     type: 'item',
            //     icon: 'store',
            //     url: '/hero/franchisees'
            // },
            {
                id: 'reports',
                title: 'Отчёты',
                translate: 'NAV.HERO.reports',
                type: 'item',
                icon: 'assessment',
                url: '/hero/reports'
            },
            {
                id: 'cities',
                title: 'Города',
                // translate: 'NAV.HERO.CITIES',
                type: 'item',
                icon: 'location_city',
                url: '/hero/cities'
            }
        ]
    },
    {
        id: 'market',
        hidden: true
    }
];