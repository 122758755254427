/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i3 from "./layout/vertical/layout-1/layout-1.component";
import * as i4 from "../@fuse/services/config.service";
import * as i5 from "./layout/vertical/layout-2/layout-2.component.ngfactory";
import * as i6 from "./layout/vertical/layout-2/layout-2.component";
import * as i7 from "./layout/vertical/layout-3/layout-3.component.ngfactory";
import * as i8 from "./layout/vertical/layout-3/layout-3.component";
import * as i9 from "./layout/horizontal/layout-1/layout-1.component.ngfactory";
import * as i10 from "./layout/horizontal/layout-1/layout-1.component";
import * as i11 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i16 from "@angular/material/icon";
import * as i17 from "../@fuse/components/sidebar/sidebar.component.ngfactory";
import * as i18 from "../@fuse/components/sidebar/sidebar.component";
import * as i19 from "@angular/animations";
import * as i20 from "../@fuse/services/match-media.service";
import * as i21 from "../@fuse/components/sidebar/sidebar.service";
import * as i22 from "@angular/flex-layout/core";
import * as i23 from "../@fuse/components/theme-options/theme-options.component.ngfactory";
import * as i24 from "../@fuse/components/theme-options/theme-options.component";
import * as i25 from "@angular/common";
import * as i26 from "@angular/forms";
import * as i27 from "../@fuse/components/navigation/navigation.service";
import * as i28 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i29 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i30 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i31 from "./app.component";
import * as i32 from "../@fuse/services/splash-screen.service";
import * as i33 from "../@fuse/services/translation-loader.service";
import * as i34 from "@ngx-translate/core";
import * as i35 from "@angular/cdk/platform";
import * as i36 from "../@progbonus/auth/auth.service";
import * as i37 from "../@progbonus/services/market/current-market.service";
import * as i38 from "@angular/router";
import * as i39 from "../@progbonus/services/app-nav.service";
import * as i40 from "@angular/material/snack-bar";
import * as i41 from "ngx-cookie-service";
import * as i42 from "../@progbonus/logger/logger.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-2", [], null, null, null, i5.View_VerticalLayout2Component_0, i5.RenderType_VerticalLayout2Component)), i1.ɵdid(2, 245760, null, 0, i6.VerticalLayout2Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-3", [], null, null, null, i7.View_VerticalLayout3Component_0, i7.RenderType_VerticalLayout3Component)), i1.ɵdid(2, 245760, null, 0, i8.VerticalLayout3Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "horizontal-layout-1", [], null, null, null, i9.View_HorizontalLayout1Component_0, i9.RenderType_HorizontalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i10.HorizontalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "warn mat-elevation-z2 theme-options-button"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpen("themeOptionsPanel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i15.View_MatIcon_0, i15.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i16.MatIcon, [i1.ElementRef, i16.MatIconRegistry, [8, null], [2, i16.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["settings"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "fuse-sidebar", [["class", "theme-options-sidebar"], ["name", "themeOptionsPanel"], ["position", "right"]], [[2, "open", null], [2, "locked-open", null], [2, "unfolded", null], [2, "animations-enabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_FuseSidebarComponent_0, i17.RenderType_FuseSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i18.FuseSidebarComponent, [i19.AnimationBuilder, i1.ChangeDetectorRef, i1.ElementRef, i4.FuseConfigService, i20.FuseMatchMediaService, i21.FuseSidebarService, i22.MediaObserver, i1.Renderer2], { name: [0, "name"], position: [1, "position"], invisibleOverlay: [2, "invisibleOverlay"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "fuse-theme-options", [], [[2, "bar-closed", null]], null, null, i23.View_FuseThemeOptionsComponent_0, i23.RenderType_FuseThemeOptionsComponent)), i1.ɵdid(3, 245760, null, 0, i24.FuseThemeOptionsComponent, [i25.DOCUMENT, i26.FormBuilder, i4.FuseConfigService, i27.FuseNavigationService, i21.FuseSidebarService, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_4 = "themeOptionsPanel"; var currVal_5 = "right"; var currVal_6 = true; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).opened; var currVal_1 = i1.ɵnov(_v, 1).isLockedOpen; var currVal_2 = i1.ɵnov(_v, 1).unfolded; var currVal_3 = i1.ɵnov(_v, 1)._animationsEnabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = i1.ɵnov(_v, 3).barClosed; _ck(_v, 2, 0, currVal_7); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i28.View_FuseProgressBarComponent_0, i28.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i29.FuseProgressBarComponent, [i30.FuseProgressBarService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(7, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(9, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(11, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(13, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.fuseConfig.layout.style === "vertical-layout-1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.fuseConfig.layout.style === "vertical-layout-2"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.fuseConfig.layout.style === "vertical-layout-3"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.fuseConfig.layout.style === "horizontal-layout-1"); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.auth.isArchitector; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.auth.isArchitector; _ck(_v, 13, 0, currVal_5); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clearCookie($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i31.AppComponent, [i25.DOCUMENT, i4.FuseConfigService, i27.FuseNavigationService, i21.FuseSidebarService, i32.FuseSplashScreenService, i33.FuseTranslationLoaderService, i34.TranslateService, i35.Platform, i36.AuthService, i37.CurrentMarketService, i38.Router, i39.AppNavService, i40.MatSnackBar, i41.CookieService, i42.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i31.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
