import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpHeaders
} from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import {
    FirestoreSettingsToken,
    AngularFirestoreModule
} from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    MatMomentDateModule,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import {
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { appRoutes } from './app.routes';
import { LoggerService } from '@progbonus/logger/logger.service';
import { ConsoleLoggerService } from '@progbonus/logger/console-logger.service';
import { environment } from 'environments/environment';
import { JwtInterceptor } from '@progbonus/helpers/jwt.interceptor';
import { ErrorInterceptor } from '@progbonus/helpers/error.interceptor';
import { ProgBonusSharedModule } from '@progbonus/shared.module';
import {
    AngularODataModule,
    ODataServiceFactory,
    ODataConfiguration
} from 'angular-odata-es5';

import { ApolloModule, Apollo } from 'apollo-angular';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { DeviceDetectorModule } from 'ngx-device-detector';

// locale
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import {
    registerLocaleData,
    LocationStrategy,
    PathLocationStrategy
} from '@angular/common';
import { CurrentMarketService } from '@progbonus/services/market/current-market.service';
import { PapaParseModule } from 'ngx-papaparse';
import { AuthService } from '@progbonus/auth/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { LogoutComponent } from './main/logout/logout.component';
import { CookieService } from 'ngx-cookie-service';

export function tokenGetter(): string {
    return localStorage.getItem('access_token');
}

registerLocaleData(localeRu, localeRuExtra);

@NgModule({
    declarations: [AppComponent, LogoutComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                skipWhenExpired: true
                // whitelistedDomains: ['example.com'],
                // blacklistedRoutes: ['example.com/examplebadroute/']
            }
        }),

        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        DeviceDetectorModule, // .forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Firebase
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule, // imports firebase/storage only needed for storage features

        // App modules
        LayoutModule,

        ProgBonusSharedModule,

        // OData
        AngularODataModule.forRoot(),

        PapaParseModule,
        ApolloModule,
        HttpLinkModule
    ],
    providers: [
        // AngularFirestore,
        // AngularFireAuth,
        // AngularFireStorage,
        { provide: LOCALE_ID, useValue: environment.localeId },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        { provide: FirestoreSettingsToken, useValue: {} },
        { provide: LoggerService, useClass: ConsoleLoggerService },

        {
            provide: ODataConfiguration,
            useFactory: (market: CurrentMarketService) => {
                const config = new ODataConfiguration();
                config.baseUrl = `${environment.progbonusApi}odata`;

                let headers: HttpHeaders = new HttpHeaders();
                // headers = headers.append('Accept', 'application/json');
                headers = headers.append('X-Market', market.id);

                // Set some new `customRequestOptions` here as an example
                config.defaultRequestOptions.headers = headers;
                return config;
            },
            deps: [CurrentMarketService]
        },
        ODataServiceFactory,
        CookieService

        // provider used to create fake backend
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        apollo: Apollo,
        auth: AuthService,
        market: CurrentMarketService,
        private readonly _logger: LoggerService
    ) {
        const httpLink = createHttpLink({
            uri: environment.graphQlUrl
        });

        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const token = auth.accessToken;
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : '',
                    ['X-Market']: market.id
                }
            };
        });

        apollo.create({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({ addTypename: false })
        });
    }
}
