export const replaceAll = (
    str: any,
    search: string | string[],
    replacement: string
) => {
    if (Array.isArray(search)) {
        let res = str + '';
        search.forEach(s => (res = res.split(s).join(replacement)));
        return res;
    }

    const d = search as string;
    return (str + '').split(d).join(replacement);
};

export const normalizeDate = (value: any): string => {
    let t = replaceAll(value, '.', '/');
    t = replaceAll(t, '-', '/');
    const arr = t.split('/');

    const d = arr.length > 0 ? addPad(arr[0]) : null;
    const m = arr.length > 1 ? addPad(arr[1]) : null;
    const y = arr.length > 2 && arr[2].length === 4 ? arr[2] + '' : null;
    const val = d && m ? `${d}/${m}${y ? '/' + y : ''}` : '';
    return val;
};

export const addPad = (num: string | number, count: number = 2): string => {
    const n = +num;
    if (n < 10) {
        return '0' + n;
    }
    return n + '';
};

export const isValidFullNameString = (input: string): boolean => {
    if (!input) {
        return false;
    }
    const letters = /^[A-zА-я_ ]+$/;
    if (input.match(letters)) {
        return true;
    } else {
        return false;
    }
};

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
